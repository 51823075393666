.card-container1 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .card1 {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    width: 32%;
    position: relative;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
  }
  
  .card-icon1 {
    font-size: 18px;
    position: absolute;
    background: #c25050;
    border-radius: 40px;
width: 40px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
  }
  
  .card-content1 {
  }
  
  .card-title1 {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
  }
  
  .card-description1 {
    font-size: 14px;
    color: #666;
  }
  
  @media screen and (max-width: 998px) {
    .card1{
        width: 100%;
    }
  }