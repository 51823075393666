.menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: white;
    padding: 10px 160px;
    position: fixed ;
    top: 0;
    z-index: 1000;
    width: 100vw;
}

.logo img {
    height: auto;
    max-height: 60px; /* Maksimum yüksekliği belirleyelim */
    width: auto;
    max-width: 140px; /* Maksimum genişliği belirleyelim */
}

.nav-links {
    display: flex;
    gap: 20px;
}
.nav-links>* {
margin: 10px;
padding: 10px;
}
.nav-links a {
    color: black;
    text-decoration: none;
    transition: color 0.3s;
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-links a:hover {
    color: #333;
    filter: drop-shadow(0 0 1rem #f4ba26);
}

.hamburger-menu {
    display: none;
    cursor: pointer;
}

.hamburger-menu .bar {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 3px 0;
    transition: 0.4s;
}
.hamburger-menu.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-4px, 4px);
}

.hamburger-menu.active .bar:nth-child(2) {
    opacity: 0;
}

.hamburger-menu.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-4px, -4px);
}

.nav-links a.active {
    background-color: #f4ba26    !important;
    color: #222   !important; 
    border-radius: 10px;
}

@media (max-width: 768px) {
    .menu-header {
        padding: 10px 40px;
    }
    .nav-links {
        flex-direction: column;
        background-color: #fff;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        transform: scaleY(0); /* Başlangıçta menüyü gizle */
        transform-origin: top; /* Animasyonun başladığı yer */
        transition: transform 0.3s ease; /* Animasyon süresi ve stilini ayarla */
        box-shadow: 10px 10px 10px rgba(256, 256, 256, 0.2);
    }
    .logo img {
        max-width: 120px; /* Mobilde logo daha küçük olacak */
        max-height: 50px;
    }
    .nav-links.open {
        transform: scaleY(1); /* Menü açıkken görünür yap */
    }

    .hamburger-menu {
        display: block;
    }
}
