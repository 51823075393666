@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
    font-family: 'Roboto', sans-serif !important;
    font-size: 17px !important;
    background-color: #333  !important; 

}
.sbg{
  background-color: #333;
}

/* ContactForm.css */
.underline {
  height: 2px;
  background-color: #000; /* Ya da tercih ettiğiniz bir renk */
  width: 8%; /* Çizginin konteyner genişliği kadar uzanmasını sağlar */
  margin-bottom: 20px; /* Başlık ve form arasında biraz boşluk bırakır */
}

.bg-altin{
  background-color: #DAA520  !important;

}
.text-altin{
  color: #DAA520  !important;

}
.button-altin {
  background-color: #e3d5ca !important;
  color: #000 !important; 
}

.button-bakir {
  background-color: #C87533 !important; 
  color: #fff !important; 
}
.product-krem {
  background-color: #FFF9E3 !important; 
}

.product-gri {
  background-color: #E0E0E0 !important; 
}
.text-bej {
  color: #F5F5DC !important;
}
.text-siyah {
  color: #222 !important;
}

.title-krem {
  color: #FFFDD0 !important;
}
.vurgu-yesil {
  color: #013220 !important; 
}

.aksiyon-gri {
  color: #383838 !important; 
}
.content{
  margin-top: 90px;
}
.pointer{
  cursor: pointer !important;
}

/* services css */
.border-top-dashed{
  border-top: 2px dashed #DAA520 !important;  
}
.sidebar-menu1 {
  display: flex;
  justify-content: space-between; /* This will space out the buttons evenly */
  margin-bottom: 20px; /* Spacing between menu and content */
}

.sidebar-menu1 button {
  flex: 1; /* Each button will take up equal space */
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #333;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease;
  margin: 10px;
  border: 2px solid #efb627;
}

/* Button hover effect */
.sidebar-menu1 button:hover {
  background-color: #efb627;
}

/* Active tab styles */
.sidebar-menu1 button.active {
  background-color: #DAA520;
  border-bottom: 2px solid #444;
  color: #fff;
}

/* Content styles */
.content1 {
  padding: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar-menu1 {
    flex-direction: column; /* Stack buttons vertically on small screens */
  }

  .sidebar-menu1 button {
    margin-bottom: 10px; /* Add space between stacked buttons */
  }
}
.img-card1 {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
}
/* services css */