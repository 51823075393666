.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

.fadeInRight {
  animation-name: fadeInRight;
}
.about-page h1, .about-page h2 {
  color: #fff; /* Başlık rengi */
  z-index: 1000;
}

.phone h1 {
  position: relative;
  z-index: 2; /* h1 etiketi, karartma katmanının üzerinde */
}
.phone::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.about-page p {
  color: #fcfcfc; /* Paragraf metin rengi */
}
.kahvebg{
  background: url(../Image/kahvebg.jpg) no-repeat center center fixed;
  background-size: cover;
  position: relative;
}
.kahvebg>*{
  position: relative;
  z-index: 2; 
}
.kahvebg::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6); /* Karartma efektinin şiddetini buradan ayarlayabilirsiniz */
  z-index: 1;
}


.about-page {
  color: #333; /* Metin rengi */
}

.about-page h1, .about-page h2 {
  color: white; /* Başlık renkleri */
}

.about-page p {
  line-height: 1.6; /* Paragraf satır yüksekliği */
}


.icon {
  color: #f4ba26 ; /* İkon renkleri */
}
.faq-container {
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.faq-item {

  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
}

.faq-question {
  font-weight: bold;
  color: #f4ba26;

}

.faq-answer {
  margin-top: 5px;
  color: #e9e4e4;

}

