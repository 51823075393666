
.contact-info {
display: inline-block;
width: 100%;
text-align: center;
margin-bottom: 10px;
}
.contact-info-icon {
margin-bottom: 15px;
}
.contact-info-item {
background: #DAA520;
padding: 20px 0px;
max-height: 250px;
border-radius: 8px;
}
.contact-page-sec .contact-page-form h2 {
color: #071c34;
text-transform: capitalize;
font-size: 22px;
font-weight: 700;
}
.contact-page-sec{
padding: 80px 0px;

}
.contact-page-form .col-md-6.col-sm-6.col-xs-12 {
padding-left: 0;
}  
.contact-page-form.contact-form input {
margin-bottom: 5px;
}  
.contact-page-form.contact-form textarea {
height: 110px;
}
.contact-page-form.contact-form input[type="submit"] {
background: #071c34;
width: 150px;
border-color: #071c34;
}
.contact-info-icon i {
font-size: 48px;
color: #DAA520;
}
.contact-info-text p{margin-bottom:0px;}
.contact-info-text h2 {
color: #333;
font-size: 22px;
text-transform: capitalize;
font-weight: 600;
margin-bottom: 10px;
}
.contact-info-text span {
color: #333;
font-size: 16px;
display: inline-block;
width: 100%;
}

.contact-page-form input {
background: #f9f9f9 none repeat scroll 0 0;
border: 1px solid #f9f9f9;
margin-bottom: 20px;
padding: 12px 16px;
width: 100%;
border-radius: 4px;
}

.contact-page-form .message-input {
display: inline-block;
width: 100%;
padding-left: 0;
}
.single-input-field textarea {
background: #f9f9f9 none repeat scroll 0 0;
border: 1px solid #f9f9f9;
width: 100%;
height: 120px;
padding: 12px 16px;
border-radius: 4px;
}
.single-input-fieldsbtn input[type="submit"] {
background: #DAA520 none repeat scroll 0 0;
color: #fff;
display: inline-block;
font-weight: 600;
padding: 10px 0;
text-transform: capitalize;
width: 150px;
margin-top: 20px;
font-size: 16px;
}
.single-input-fieldsbtn input[type="submit"]:hover{background:#071c34;transition: all 0.4s ease-in-out 0s;border-color:#071c34}
.single-input-field  h4 {
color: #464646;
text-transform: capitalize;
font-size: 14px;
}
.contact-page-form {
display: inline-block;
width: 100%;
margin-top: 30px;
}

.contact-page-map {
margin-top: 36px;
}
.contact-page-form form {
padding: 20px 15px 0;
}