.bg-yellow {
    background-color: #e3d5ca; /* Sarı arka plan */
}

.text-white p {
    color: #fff; /* Beyaz yazı rengi */
}

.icon-wrapper {
    background-color: #333;
    border-radius: 50%; /* Yuvarlak arka plan */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-right: 10px;
}

.custom-hr {
    border: none;
    height: 2px; /* Çizginin kalınlığını ayarlayabilirsiniz */
    background-color: #e3d5ca; /* Çizginin rengini ayarlayabilirsiniz */
    margin: 10px 0; /* Üst ve alt boşlukları ayarlayabilirsiniz */
    width: 4vw;
}
.custom-hr-b {
    border: none;
    height: 2px; /* Çizginin kalınlığını ayarlayabilirsiniz */
    background-color: #333; /* Çizginin rengini ayarlayabilirsiniz */
    margin: 10px 0; /* Üst ve alt boşlukları ayarlayabilirsiniz */
    width: 4vw;
}


@keyframes fadeIn {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

@keyframes popIn {
    0% { transform: scale(0); opacity: 0; }
    60% { transform: scale(1.1); opacity: 1; }
    100% { transform: scale(1); }
}

.animated-image {
    width: 100%; /* Konteynerin genişliğini doldur */
    height: auto; /* Yüksekliği orantılı tut */
    object-fit: cover; /* Görseli konteynerine sığdır ve bozulmamasını sağla */
    animation: popIn 1s ease-out;
}


.fade-in-text {
    animation: fadeIn 2s ease-out;
    opacity: 0; /* Başlangıçta görünmez */
}
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .animated-text {
    animation: fadeIn 2s ease-in-out;
  }
  .banner-container {
    background-image: url('../Image/h1.jpg');
    height: 200px; /* Adjust the height as needed */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }
  
  .text-overlay h1 {
    font-size: 2rem; /* Adjust the size as needed */
    margin-bottom: 0.5rem;
  }
  
  .text-overlay p {
    font-size: 1rem; /* Adjust the size as needed */
  }
  
  .info-container {
    background-image: url('../Image/h1.jpg'); /* Arka plan resminin yolu */
    background-size: cover; /* Resmin konteyneri kaplamasını sağlar */
    background-repeat: no-repeat; /* Resmin tekrar etmesini önler */
    background-position: center center; /* Resmi ortalar */
    color: white; /* Metin rengini beyaza çevirir */
    padding: 20px; /* İç boşluk ekler */
    /* height: 25vh; */
  }
  
  .card {
    background-color: rgba(0, 0, 0, 0.5); /* Kartların yarı şeffaf bir arka plana sahip olmasını sağlar */
    margin-bottom: 20px; /* Kartlar arası boşluk ekler */
    border: none;
  }
  
  .card-body {
    padding: 15px; /* Kartın iç boşluğunu ayarlar */
  }
  
  .card-title {
    margin-top: 10px; /* Başlık ve ikon arasında boşluk ekler */
    font-size: 2.5rem; /* Başlık boyutunu büyütür */
  }
  
  .card-text {
    font-size: 1.5rem; /* Metin boyutunu büyütür */
  }
  
  @media (max-width: 768px) {
    .card {
      margin-left: 15px;
      margin-right: 15px;
    }

  }
  /* Card.css */
.card1 {
    border: 1px solid #ddd; /* Çerçeve rengi */
    border-radius: 4px; /* Kenar yumuşatma */
    width: 200px; /* Kart genişliği */
    margin: 10px; /* Kart arası boşluk */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Gölge efekti */
    overflow: hidden; /* İçerik sınırların dışına taşarsa gizle */
    background: #fff; /* Kart arkaplan rengi */
  }
  
  .card-image1 {
    width: 100%; /* Resmi kart genişliğine sığdır */
    height: auto; /* Resmi orantılı olarak ölçekle */
    display: block; /* Resmi blok elementi olarak göster */
  }
  
  .card-body1 {
    padding: 10px; /* İçerik için padding */
    text-align: center; /* Metni ortala */
  }
  
  .card-title1 {
    margin: 0; /* Başlık için üst ve alt marjı sıfırla */
    font-size: 1em; /* Başlık font boyutu */
    color: #333; /* Başlık font rengi */
  }
  


/* card  */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;700;800;900&display=swap');

*{
    padding: 0;
    margin: 0;
}

:root{
    font-size: 16px;
    font-family: "Raleway";
    --heading-color: hsl(0, 0%, 7%);
    --date-text-color: hsl(0, 0%, 51%);
    --previous-price-text-color: hsl(0, 98%, 44%);
    --current-price-text-color: hsla(0, 0%, 0%, 0.822);
    --liked-heart-icon-color: hsl(0, 98%, 44%);
    --heart-icon-color: whitesmoke;
    --pricing-font-weight: 800;
    --title-font-weight: 800;
    --date-font-weight: 550;

    /* Cards colors */
    --card-main-color : whitesmoke;
    --card-1-secondary-color: rgb(192, 82, 82);
    --card-2-secondary-color: rgb(67,53,27);
    --card-3-secondary-color: rgb(178,180,179);
    --card-4-secondary-color: rgb(96,109,117); 
    --card-5-secondary-color: rgb(192, 82, 82);
    --card-6-secondary-color: rgb(67,53,27);
    --card-7-secondary-color: rgb(178,180,179);
    --card-8-secondary-color: rgb(96,109,117);
}



.container1 input{
    display: none;
}

.container1{
    max-width: 100em;
    /* background-color: lightgreen; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    margin: auto;
    min-height: 30em;
    padding: 3em 1em 1em 1em;
    box-sizing: border-box;
}

/* Card Styling */
.card-div{
    width: 20em;
    min-height: 20em;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background-color: var(--card-main-color);
    margin: 0.6em;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: transform 0.2s ease-in-out;
}

/* ======== General styling of all cards and heart divs ========== */
.img-div{
    width: 100%;
    height: 8em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    z-index: 1;
    border-radius: 0 0 10px 10px;
}

.img-div img{
  object-fit: contain;
  height: 250px;
    transform-origin: bottom;
    transform: translateY(7.5%);
    transition: transform 0.3s ease-in-out
}

/* === Styling the like Icon ==== */
.like-icon-div{
    padding: 1em 1em 0 1em;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    font-size: 1.5em;
    color: var(--heart-icon-color);
    border-radius:10px 10px 0 0;
    display: flex;
    justify-content: flex-end;
}

.like-icon-div-child{
    /* background-color: yellow; */
    width: 1em;
    height: 1em;
    position: relative;
    z-index: 3;
    cursor: pointer;
}

.heart-empty{
    position: absolute;
    left: 0;
    opacity: 1;
}

.heart-fill{
    position: absolute;
    left: 0;
    opacity: 0;
    transform: scale(0);
    transition: transform 0.25s ease-in-out, opacity 0.2s ease-in-out;
}


/* God of war image and heart-background*/
.gow-img-div{
    background-color: var(--card-1-secondary-color);
}

.gow-img-div img{
    width: 65%;
    transform: translateY(7.5%);
    /* background-color: whitesmoke; */
}

.card-1 .like-icon-div{
    background-color: var(--card-1-secondary-color);
}


/* Sekiro image and heart-background */
.sekiro-img-div{
    background-color: var(--card-2-secondary-color);
}

.sekiro-img-div img{
    width: 45%;
    transform: translateY(7.5%);
}

.card-2 .like-icon-div{
    background-color: var(--card-2-secondary-color);
}


/* Dazai image and heart-background */
.dazai-img-div{
    background-color: var(--card-3-secondary-color);
}

.dazai-img-div img{
    width: 80%;
    transform: translateY(7.5%);
}

.card-3 .like-icon-div{
    background-color: var(--card-3-secondary-color);
    
}

/* U4 image and heart-background*/
.u4-img-div{
    background-color: var(--card-4-secondary-color);
}

.u4-img-div img{
    width: 50%;
    transform: translateY(7.5%);
}

.card-4 .like-icon-div{
    background-color: var(--card-4-secondary-color);
}
.card-5 .like-icon-div{
  background-color: var(--card-5-secondary-color);
}
.card-6 .like-icon-div{
  background-color: var(--card-5-secondary-color);
}
.card-7 .like-icon-div{
  background-color: var(--card-5-secondary-color);
}
.card-8 .like-icon-div{
  background-color: var(--card-5-secondary-color);
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-div {
  opacity: 0; 
  animation: fadeInUp 0.5s ease forwards;
  /* animation-delay will be set dynamically */
}
/* ======== text Container Styling general ======== */

.text-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
    padding: 0 1.5em;
    padding-top: 7em;
    padding-bottom: 1em;
    box-sizing: border-box;
}

.text-container .item-name,
.text-container .date{
    margin: 0.25em 0;
    text-align: center;
}

.text-container .item-name{
    font-size: 1.2em;
    font-weight: var(--title-font-weight);
    color: var(--heading-color);
}

.text-container .date{
    font-size: 0.9em;
    font-weight: var(--date-font-weight);
    color: var(--date-text-color);
}

/* === Pricing and cart div  ===== */
.pricing-and-cart{
    /* background-color: wheat; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.25em 0 1em 0;
}

.pricing{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.previous-price{
    font-size: 0.8rem;
    font-weight: var(--pricing-font-weight);
    color: var(--previous-price-text-color);
    text-decoration: line-through;
    /* background-color: whitesmoke; */
    text-align: left;
}

.current-price{
    color: var(--current-price-text-color);
    font-size: 1.3rem;
    font-weight: var(--pricing-font-weight);
    /* background-color: yellow; */
    margin: 0;
}

.pricing-and-cart{
    width: 100%;
}

.fa-shopping-cart{
    font-size: 1.3rem;
    top: 0;
    transform: translateY(50%);
}


/* ====== Hover effects ======= */
.card-div:hover .img-div img{
    transform: translateY(7.5%) scale(1.2);
}

.card-div:hover{
    transform: translate(0, -10px);
}

.like-icon-div-child:hover .heart-fill{
    opacity: 1;
    transform: scale(1);
}

.like-icon-div-child:hover .heart-empty{
    transition-delay: 0.25s;
    opacity: 0;
}

#card-1-like:checked ~ .heart-empty,
#card-2-like:checked ~ .heart-empty,
#card-3-like:checked ~ .heart-empty,
#card-4-like:checked ~ .heart-empty{ 
        opacity: 0;
}

#card-1-like:checked ~ .heart-fill,
#card-2-like:checked ~ .heart-fill,
#card-3-like:checked ~ .heart-fill,
#card-4-like:checked ~ .heart-fill{
    animation: like-animation 0.25s ease-in-out forwards;
}

#card-1-like:not(:checked) ~ .heart-fill,
#card-2-like:not(:checked) ~ .heart-fill,
#card-3-like:not(:checked) ~ .heart-fill,
#card-4-like:not(:checked) ~ .heart-fill{
    animation: unlike-animation 0.25s ease-in-out ;
}


@keyframes like-animation{
    0%{
        opacity: 1;
        transform: scale(1)
    }
    
    50%{
        opacity: 1;
        color: var(--liked-heart-icon-color);
        transform: scale(0.5);
    }

    100%{
        opacity: 1;
        color: var(--liked-heart-icon-color);
        transform: scale(1.0);
    }
}

@keyframes unlike-animation{
    0%{
        opacity: 1;
        transform: scale(1)
    }
    
    50%{
        opacity: 1;
        transform: scale(0.5);
    }

    100%{
        opacity: 1;
        color: var(--heart-icon-color);
        transform: scale(1.0);
    }
}
/* card  */

/*  carousel css */
.carousel-container1 {
    position: relative;
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-item1 {
    position: absolute; /* Bu satırı ekleyin */
    width: 100vw; /* Carousel genişliğine tam sığar */
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    opacity: 0; /* Başlangıçta görünmez */
    transform: scale(0.5); /* Küçültülmüş halde başlar */
    transition: transform 1s ease, opacity 1s ease;
    overflow: hidden;
  }
  
  .carousel-slide {
    width: 100%;
    height: 100%;
    position: absolute; /* Tüm slaytları üst üste bindir */
    transition: opacity 1s ease-in-out; /* Yumuşak geçiş efekti */
}
  
  .carousel-slide .carousel-item1.active-slide {
    opacity: 1; /* Aktif slayt görünür olur */
    transform: scale(1); /* Normal boyuta döner */
    z-index: 100;
  }
  
  .carousel-item1 img {
    width: 100%; /* Resmi genişliğe göre ölçekle */
    height: auto; /* Orantılı yükseklik */
    object-fit: cover; /* Resmi kırpma veya ölçekleme */
  }
  
  .carousel-item1 h2, 
  .carousel-item1 p {
    /* Başlangıçta gizli */
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .carousel-item1.active-slide h2 {
    /* h2 için animasyon */
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s; /* Animasyon gecikmesi */
  }
  
  .carousel-item1.active-slide .p1 {
    /* p için animasyon */
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.6s; /* Animasyon gecikmesi, h2'den sonra */
  }
  .carousel-item1.active-slide .p2 {
    /* p için animasyon */
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.9s; /* Animasyon gecikmesi, h2'den sonra */
  }
  .dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust darkness here */
    z-index: 1; /* Overlay above the image */
  }
  .text-content {
    position: relative;
    z-index: 2; /* Text above the overlay */
  }
  .button1 {
    background: rgba(255, 255, 255, 0.5); /* Buton arka planı */
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    z-index: 100;
    height: 50px; /* Buton yüksekliği */
    width: 50px; /* Buton genişliği */
    margin: 0 10px; /* Sağ ve sol boşluk */
    position: absolute;
    z-index: 200;
  }
  .resim{
    height: 80vh;
  }
  .resim img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Resmi kapsayıcıya sığdır, gerektiğinde kırp */
    object-position: center; /* Resmin merkezini kapsayıcıya hizala */
}
h1 {
  font-family: 'Arial', sans-serif;
  font-size: 44px;
  font-weight: bold;
  color: #fff;
}
.phot{
  scale: 0.9;
  animation: fadeIn 2s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.phot:hover{
  scale: 1;
  animation: fadeIn 2s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/* Soru Stili */
.p1 {
  font-family: 'Verdana', sans-serif;
  font-size: 20px;
  font-weight: normal;
  color: #fff;
}

/* Açıklama Stili */
.p2 {
  font-family: 'Verdana', serif;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
}
  .carousel-container1 > .button1:first-of-type {
    left: 0;
  }
  
  .carousel-container1 > .button1:last-of-type {
    right: 0;
  }
  
  .button1:hover {
    background: rgba(255, 255, 255, 0.3); /* Buton arka planı */
  }
 
/*   carousel css */


/* more button */
.animated-text {
  font-family: 'Arial', sans-serif; /* Font ailesi */
  font-size: 1.2em; /* Font büyüklüğü */
  line-height: 1.5; /* Satır yüksekliği */
  color: #333; /* Yazı rengi */
  animation: fadeIn 2s; /* Animasyon efekti */
}
.text-center {
  text-align: center; /* Merkezi hizalama */
}
.custom-h1 {
  width: 30%; /* HR genişliği */
  height: 2px; /* HR yüksekliği */
  background-color: #DAA520; /* HR rengi */
  margin: 10px auto; /* Otomatik marjin */
}
.custom-h1s {
  width: 30%; /* HR genişliği */
  height: 2px; /* HR yüksekliği */
  background-color: #333; /* HR rengi */
  margin: 10px auto; /* Otomatik marjin */
}
.more-button {
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DAA520;
  box-shadow: 0 0 10px #DAA520;
}

.more-button .button-icon {
  margin-left: 10px;
  transition: transform 0.3s ease;
  color: #DAA520;
}

.more-button:hover {
  background-color: #DAA520;
  transform: scale(1.1);
}

.more-button:hover .button-icon {
  transform: translateX(5px);
  color: #ddd;
}
.animated-button1 {
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex; /* Flexbox kullan */
  align-items: center; /* İkon ve metni dikey olarak ortala */
  border: 2px solid  #DAA520;
  box-shadow: 0 0 10px #DAA520;
}

.animated-button1 svg {
  margin-right: 10px; /* İkon ve metin arasında boşluk */
}

.animated-button1:hover {
  background-color: #DAA520;
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .resim{
    height: 55vh;
  }
}

/* more button */